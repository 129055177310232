import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './PortalEditor.module.css';
import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';
import Title from '../../../elements/Title';
import Text from '../../../elements/Text';

const PortalEditor = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/portal-editor.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              subtitle
            }
          }
        }
      }
    }
  `);

  const { title, subtitle } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.portalEditorContainer}>
      <Container>
        <Container type="mobile" className="d-flex flex-column align-items-center">
          <Title type="small" className={`${styles.title} mb-3`}>{title}</Title>
          <Text className={styles.subtitle}>{subtitle}</Text>
          <Image name="portal-launch/portal_editor" className={styles.portalEditorImage} />
        </Container>
        <div className={styles.confettiImgDesktop} />
        <Image name="portal-launch/editor_confetti_mobile" className={styles.confettiImgMobile} />
      </Container>
    </div>
  )
}

export default PortalEditor;
