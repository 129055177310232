import React from 'react';
import PropTypes from 'prop-types';

import styles from './PricingSwitcher.module.css';

const PricingSwitcher = ({ billingType, onChangeBillingType }) => {
  return (
    <div className={styles.pricingSwitcher}>
      <div
        className={`
          ${styles.pricingSwitcher__tab} 
          ${styles.pricingSwitcher__tabLeft} 
          ${billingType === 'month' ? styles.pricingSwitcherSelected : ''}
        `}
        onClick={() => onChangeBillingType('month')}
        onKeyDown={() => onChangeBillingType('month')}
        role="button"
        tabIndex={0}
      >
        Monthly billing
      </div>
      <div
        className={`
          ${styles.pricingSwitcher__tab} 
          ${styles.pricingSwitcher__tabRight} 
          ${billingType === 'year' ? styles.pricingSwitcherSelected : ''}
        `}
        onClick={() => onChangeBillingType('year')}
        onKeyDown={() => onChangeBillingType('month')}
        role="button"
        tabIndex={0}
      >
        Annual billing
      </div>
    </div>
  )
};

PricingSwitcher.propTypes = {
  billingType: PropTypes.oneOf(['month', 'year']).isRequired,
  onChangeBillingType: PropTypes.func.isRequired
};

export default PricingSwitcher;