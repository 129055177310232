import React, { useState } from 'react';

import styles from './FrequentlyAskedQuestions.module.css';

import Title from '../../elements/Title';
import Container from '../Container';
import Text from '../../elements/Text';
import Image from '../../elements/Image';

const FrequentlyAskedQuestions = ({ faq }) => {

  const [openedItemIndex, setOpenedItemIndex] = useState(null);

  const toggleAccordionItem = index => {
    setOpenedItemIndex(index === openedItemIndex ? null : index);
  };

  return (
    <div className={styles.frequentlyAskedQuestions}>
      <Container>
        <Container type="mobile">
          <Title className={styles.frequentlyAskedQuestions__title}>Frequently Asked Questions</Title>
          <div className={styles.accordion}>
            {
              faq.map((item, index) => (
                <div
                  key={index}
                  className={`
                    ${styles.accordion__item} 
                    ${index === openedItemIndex ? styles.accordion__itemOpened : ''}
                    ${item.big ? styles.accordion__itemBig : ''}
                    ${item.mobileBig ? styles.accordion__itemMobileBig : ''}
                  `}
                  onClick={() => toggleAccordionItem(index)}
                  onKeyDown={() => toggleAccordionItem(index)}
                  role="button"
                  tabIndex={0}
                >
                  <div className={styles.accordion__textBlock}>
                    <div className={styles.accordion__question}>{item.question}</div>
                    <Text><span dangerouslySetInnerHTML={{ __html: item.answer }} /></Text>
                  </div>
                  <div className={index === openedItemIndex ? styles.accordion__closeSign : styles.accordion__openSign} />
                </div>
              ))
            }
          </div>
          <Image name="faq_girl" className={styles.frequentlyAskedQuestions__image} />
        </Container>
      </Container>
    </div>
  )
};

export default FrequentlyAskedQuestions;
