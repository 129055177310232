import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './ProFeatures.module.css';

import Title from '../../../../elements/Title';
import Image from '../../../../elements/Image';
import BulletedList from '../../../../elements/BulletedList';
import Text from '../../../../elements/Text';
import Container from '../../../../blocks/Container';

const ProFeatures = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/pro-features.md/" } }) {
        edges {
          node {
            frontmatter {
              features {
                image
                title
                details
              }
            }
          }
        }
      }
    }
  `);

  const { features } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <Container type="big">
      <Container type="mobile">
        <div className={styles.proFeatures}>

          <Title>
            <span className={styles.proFeature__underlinedTitle}>Pro Features</span> to <br className="mobile-br" />
            Help Creators <br className="desktop-br" /> Make <br className="mobile-br" />
            an Impact
          </Title>
          <Text className={styles.proFeatures__text}>
            Only available on the Create <br className="mobile-br" /> and Scale plans.
          </Text>
          <div className={`${styles.proFeatures__content} row mb-xl-5 mb-lg-5`}>
            {
              features.map((feature, index) => (
                <div
                  key={index}
                  className={`${styles.proFeature} col-xl-6 col-lg-6 col-12`}
                >
                  <div className={`${styles.proFeature__content} d-flex flex-column align-items-center justify-content-start`}>
                    <Image name={feature.image} className={`${styles.proFeature__image} mb-xl-4 mb-lg-4 mb-1`} />
                    <div className={`${styles.proFeature__title} mb-4`}>{feature.title}</div>
                    <BulletedList list={feature.details} color="#2056b7" />
                    {index !== features.length - 1 && <hr className={styles.proFeatures__hr} />}
                  </div>
                </div>
              ))
            }
          </div>
          <Image name="man_sitting" className={styles.proFeatures__manSitting} />
        </div>
      </Container>
    </Container>
  )
};

export default ProFeatures;
