import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './PortalOnYourSite.module.css';
import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';
import Title from '../../../elements/Title';
import Text from '../../../elements/Text';
import MainImage from '../../../../images/portal-launch/svg/on_site_image.svg';

const PortalOnYourSite = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/portal-on-your-site.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              subtitle
            }
          }
        }
      }
    }
  `);

  const { title, subtitle } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.pageContainer} id="on-your-site">
      <Container>
        <Container type="mobile">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12 pr-xl-0 pr-lg-0">
              <Title type="small" className={styles.title}>
                <span dangerouslySetInnerHTML={{ __html: title}} />
              </Title>
              <Text className={styles.subtitle}>{subtitle}</Text>
              <MainImage className={styles.image} />
              <Image name="portal-launch/on_site_image_mobile" className={styles.imageMobile} />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  )
}

export default PortalOnYourSite;
