import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import styles from './LogosList.module.css';

import Image from '../../../elements/Image';

const LogosList = ({ type, whiteLogos, greyLogos, className, onMainPage }) => {
  return (
    <div className={`${styles.logosList__logos}${className ? ` ${className}` : ''}`}>
      {
        (type === 'blue' ? whiteLogos : greyLogos).map((logo, index) => (
          <Fragment key={index}>
            <Image
              name={logo.name}
              className={`${styles.logosList__logoImage} ${onMainPage ? 'mb-xl-0 mb-lg-0' : ''} mr-xl-4 mr-lg-2 mt-xl-4 mt-lg-4 m-md-4 mr-1`}
              style={{ width: logo.width }}
            />
            {logo.break && <div className="mobile-flex-br" />}
          </Fragment>
        ))
      }
    </div>
  )
};

LogosList.propTypes = {
  type: PropTypes.oneOf(['blue', 'white']).isRequired,
  whiteLogos: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    width: PropTypes.string,
    break: PropTypes.bool
  })).isRequired,
  greyLogos: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    width: PropTypes.string,
    break: PropTypes.bool
  })).isRequired,
  className: PropTypes.string,
  onMainPage: PropTypes.bool
};

LogosList.defaultProps = {
  className: '',
  onMainPage: false
};

export default LogosList;