import React from 'react';
import PropTypes from 'prop-types';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './IntegrationsBlock.module.css';

import Container from '../Container';
import Title from '../../elements/Title';
import Text from '../../elements/Text';
import LearnMoreLink from '../../elements/LearnMoreLink';
import Pretitle from '../../elements/Pretitle';
import LogosList from './LogosList';

const IntegrationsBlock = ({ type, thinLearnMore, className, usePretitle, fullWidthOnMobile }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/integrations-block.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              subtitle
              whiteLogos {
                name
                width
                break
              }
              greyLogos {
                name
                width
                break
              }
            }
          }
        }
      }
    }
  `);

  const { subtitle, whiteLogos, greyLogos } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={`${styles.integrationsBlock} ${className}`}>
      <Container type="big">
        <div className={`${styles.integrationsBlockContent} ${type === 'white' ? styles.integrationsBlockContentWhite : ''}`}>
          {
            usePretitle &&
            <Pretitle className={`${styles.integrationsBlockContent__pretitle}`}>
              <div className={styles.integrationsBlockContent__icon} />
              Direct Integrations
            </Pretitle>
          }
          <Title className={`${styles.integrationsBlockContent__textContent} mb-4`}>
            Integrate With <br className="mobile-br" />
            Your Entire <br className="mobile-br" />
            Marketing Stack
          </Title>
          {
            fullWidthOnMobile ?
              <Container type="mobile">
                <Text className={`${styles.integrationsBlockContent__textContent} text-center`}>
                  {subtitle}
                </Text>
              </Container> :
              <Text className={`${styles.integrationsBlockContent__textContent} text-center`}>
                {subtitle}
              </Text>
          }
          <LogosList type={type} whiteLogos={whiteLogos} greyLogos={greyLogos} />
          <LearnMoreLink
            href="/integrations"
            className={`
              ${styles.integrationsBlockContent__link} 
              ${thinLearnMore ? styles.integrationsBlockContent__thinLink : ''}
              mt-5
            `}
          />
        </div>
      </Container>
    </div>
  )
};

IntegrationsBlock.defaultProps = {
  thinLearnMore: false,
  className: '',
  usePretitle: false,
  fullWidthOnMobile: false
};

IntegrationsBlock.propTypes = {
  type: PropTypes.oneOf(['blue', 'white']).isRequired,
  thinLearnMore: PropTypes.bool,
  className: PropTypes.string,
  usePretitle: PropTypes.bool,
  fullWidthOnMobile: PropTypes.bool
};

export default IntegrationsBlock;