import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './PortalHeader.module.css';

import Title from '../../../../elements/Title';
import Text from '../../../../elements/Text';
import LearnMoreLink from '../../../../elements/LearnMoreLink';
import Image from '../../../../elements/Image';

const PortalHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/portal-header-pricing.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              subtitle
              link
            }
          }
        }
      }
    }
  `);

  const { title, subtitle, link } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={`${styles.portalHeader} d-flex flex-column align-items-center`}>
      <Title className={styles.title}>{title}</Title>
      <Text className={styles.subtitle}>{subtitle}</Text>
      <LearnMoreLink href={link} className={styles.link} />
      <Image name="pricing/portal_header_confetti" className={styles.image} />
      <Image name="pricing/portal_header_confetti_mobile" className={styles.imageMobile} />
    </div>
  )
};

export default PortalHeader;
