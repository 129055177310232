import React, { useState } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './PricingContent.module.css';

import PricingSwitcher from './PricingSwitcher';
import Text from '../../../elements/Text';
import Container from '../../../blocks/Container';
import Button from '../../../elements/Button';
import ProFeatures from './ProFeatures';
import EveryPlanFeatures from './EveryPlanFeatures';
import PortalHeader from './PortalHeader/PortalHeader';
import PortalEditor from '../../portal-launch/PortalEditor';
import PortalOnYourSite from '../../portal-launch/PortalOnYourSite';

const PricingContent = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/plans.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              plans {
                title
                mostPopular
                id
                monthPrice
                yearPrice
                details
                features
              }
            }
          }
        }
      }
    }
  `);

  const { title, plans } = data.allMarkdownRemark.edges[0].node.frontmatter;

  const [billingType, setBillingType] = useState('month');

  return (
    <div className={styles.pricingContent}>
      <Container type="mobile">
        <div className="d-flex justify-content-center">
          <div className={styles.pricingContent__switcher}>
            <PricingSwitcher billingType={billingType} onChangeBillingType={billingType => setBillingType(billingType)} />
            <div
              onClick={() => setBillingType('year')}
              onKeyDown={() => setBillingType('year')}
              role="button"
              tabIndex={0}
              style={{ outline: 'none' }}
            >
              <Text className={`${styles.pricingContent__title} mt-3 text-center`}>{title}</Text>
            </div>
          </div>
        </div>
        <Container type="big">
          <div className="d-flex justify-content-center">
            <div className={styles.plansContainer}>
              {
                plans.map((plan, index) => (
                  <div
                    key={index}
                    className={`
                    ${styles.plan} 
                    ${plan.mostPopular ? styles.planMostPopular : ''}
                    ${plan.id === 'basic' ? styles.planLeft : ''}
                    ${plan.id === 'unlimited-5-sub' ? styles.planRight : ''}
                  `}
                  >
                    <div className={styles.plan__inner}>
                      <div className={styles.plan__innerTitle}>{plan.title}</div>
                      <div className={styles.plan__innerPriceContainer}>
                        <div className={styles.plan__innerPrice}>{plan[`${billingType}Price`]}</div>
                        <div className={styles.plan__innerUnit}>
                          /mo
                          {billingType === 'year' &&
                          <span className={styles.plan__innerUnitSmall}> (billed annually)</span>}
                        </div>
                      </div>
                      <div
                        className={styles.plan__details}
                        dangerouslySetInnerHTML={{ __html: plan.details || '&nbsp;' }}
                      />
                      {
                        plan.features.map((feature, index) => (
                          <div key={index} className={styles.plan__feature}>{feature}</div>
                        ))
                      }
                    </div>
                    <div className="d-flex justify-content-center">
                      <Button
                        type="action"
                        className="mt-md button-in-place"
                        href={`/signup/${plan.id}${billingType === 'year' ? '?payment_type=annually' : ''}`}
                      >
                        Get Started
                      </Button>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          <EveryPlanFeatures />
          <PortalHeader />
        </Container>
      </Container>
      <PortalEditor fromPricing={true} />
      <PortalOnYourSite fromPricing={true} />
      <ProFeatures />
    </div>
  )
};

export default PricingContent;
