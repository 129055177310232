import React from 'react';
import PropTypes from 'prop-types';

import styles from './LearnMoreLink.module.css';

const LearnMoreLink = ({ href, className, text }) => (
  <a href={href} className={`${styles.learnMoreLink} ${className}`}>{text}</a>
);

LearnMoreLink.defaultProps = {
  className: '',
  text: 'Learn More'
};

LearnMoreLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string,
  className: PropTypes.string
};

export default LearnMoreLink;