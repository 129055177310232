import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import PricingHeader from '../components/slides/pricing/PricingHeader';
import PricingContent from '../components/slides/pricing/PricingContent';
import IntegrationsBlock from '../components/blocks/IntegrationsBlock';
import FrequentlyAskedQuestions from '../components/blocks/FrequentlyAskedQuestions';
import { graphql, useStaticQuery } from 'gatsby';

const Pricing = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/pricing/FAQ.md/" } }) {
        edges {
          node {
            frontmatter {
              faq {
                question
                answer
              }
            }
          }
        }
      }
    }
  `);

  const { faq } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <Layout>
      <SEO
        title="Pricing | Deadline Funnel"
        description="Choose any plan and try Deadline Funnel free for 14 days!"
      />
      <PricingHeader />
      <PricingContent />
      <div style={{ paddingTop: '30px' }}>
        <IntegrationsBlock type="white" fullWidthOnMobile={true} />
      </div>
      <FrequentlyAskedQuestions faq={faq} />
    </Layout>
  )
};

export default Pricing;
