import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './PricingHeader.module.css';

import Title from '../../../elements/Title';
import Text from '../../../elements/Text';
import Container from '../../../blocks/Container';

const PricingHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/pricing-header.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              subtitle
            }
          }
        }
      }
    }
  `);

  const { title, subtitle } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.pricingHeader}>
      <Container type="mobile">
        <Title className={`${styles.pricingHeader__title} mb-3`}>{title}</Title>
        <Text className={`${styles.pricingHeader__subtitle} text-center`}>{subtitle}</Text>
      </Container>
    </div>
  )
};

export default PricingHeader;