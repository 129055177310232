import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './EveryPlanFeatures.module.css';

import Title from '../../../../elements/Title';
import Image from '../../../../elements/Image';
import BulletedList from '../../../../elements/BulletedList';
import LearnMoreLink from '../../../../elements/LearnMoreLink';

const EveryPlanFeatures = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/every-plan-features.md/" } }) {
        edges {
          node {
            frontmatter {
              features {
                icon
                name
                pretitle
                title
                details
                link
                color
              }
            }
          }
        }
      }
    }
  `);

  const { features } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.everyPlanFeatures}>
      <Title>
        Every Deadline Funnel <br className="desktop-br" />
        Plan Includes:
      </Title>
      <div className="row mt-xl">
        {
          features.map((feature, index) => (
            <div key={index} className={`${styles.everyPlanFeatures__item} col-xl-4 col-lg-4 col-12 d-flex justify-content-center`}>
              <div className={styles.everyPlanFeatures__descriptionContainer}>
                <div className="d-flex align-items-center mb-4">
                  <Image name={feature.icon} className={styles[`everyPlanFeatures__${feature.name}Image`]} />
                  <div className={styles.everyPlanFeatures__itemPretitle} style={{ color: feature.color }}>
                    {feature.pretitle}
                  </div>
                </div>
                <div className={`${styles.everyPlanFeatures__itemTitle} mb-4`}>{feature.title}</div>
                <div className={`${styles.everyPlanFeatures__detailsContainer} mb-40`}>
                  <BulletedList list={feature.details} color={feature.color} />
                </div>
                <LearnMoreLink href={feature.link} className={`${styles.everyPlanFeatures__link} ml-1`} />
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
};

export default EveryPlanFeatures;